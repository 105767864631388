<template>
  <div class="w-100 text-center pt-2">
    <h3 class="mb-1">
      {{ $t('empty-layout.title') }}
    </h3>
    <b-button
      v-if="canEdit"
      variant="primary"
      class="mb-2 mt-1 mr-50"
      @click="openBackoffice"
    >
      {{ $t('empty-layout.get-starter') }}
    </b-button>
    <b-button
      v-if="canEdit && hasLayoutTemplates"
      variant="outline-primary"
      class="mb-2 mt-1 ml-50"
      v-b-modal.get-template
    >
      {{ $t('empty-layout.template-button') }}
    </b-button>
    <img :src="unenabledAppsPlaceholder" class="unenabled-app-image d-block mx-auto">
    <layout-template-get-modal />
  </div>
  
</template>

<script>
import UnenabledAppsPlaceholder from '@/assets/images/placeholders/light/Background_empty.png';
import LayoutTemplateGetModal from '@core/components/layouts/apps-layout/LayoutTemplateGetModal.vue';


export default {
  name: 'EmptyLayout',
  components: {LayoutTemplateGetModal},
  computed: {
    unenabledAppsPlaceholder() {
      return UnenabledAppsPlaceholder;
    },
    canEdit() {
      return this.$store.getters.currentCollective.isStaff;
    },
    mainType() {
      return this.$store.getters.currentCollective.mainType;
    },
    hasLayoutTemplates() {
      //TODO: first check if it has templates
      return this.$store.getters.layoutTemplates?.unpaginated.length > 0;
    },
  },
  methods: {
    openBackoffice() {
      const routeData = this.$router.resolve({ name: 'settings' });
      window.open(routeData.href, '_blank');
    },
  }
};
</script>

<style lang="scss" scoped>

.unenabled-app-image {
  max-width: 500px;
}
</style>
